import React, {useEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {connect, useSelector} from 'react-redux';

import axios from "axios";
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from '@mui/material';
import {toast} from "react-toastify";

import EmailIcon from "@mui/icons-material/Email";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import {tokens} from "../../theme";
import Header from "../../components/Header";
import StatBox from '../../components/StatBox';
import MultiLineChart from '../../components/MultiLineChart';
import {requestConfig} from "../utils";

const RowTable = (name, spend, clicks, impressions, facebook_advertisers) => {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {name}
        </TableCell>
        <TableCell align="right">{spend.toFixed(2)}</TableCell>
        <TableCell align="right">{clicks}</TableCell>
        <TableCell align="right">{impressions}</TableCell>
        <TableCell align="right">{(spend / clicks || 0).toFixed(3)}</TableCell>
        <TableCell align="right">{(clicks * 100 / impressions || 0).toFixed(2)} %</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{margin: 1}}>
              <Typography variant="h6" gutterBottom component="div">
                Statistic by {name.includes('Facebook') ? 'Ad Accounts' :
                (name.includes('OutBrain') ? 'Marketers' :
                    'Advertisers'
                )}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {name.includes('Facebook') ? 'Ad Account ID' :
                        (name.includes('OutBrain') ? 'Marketer ID' :
                            'Advertiser ID'
                        )}
                    </TableCell>
                    <TableCell>
                      {name.includes('Facebook') ? 'Ad Account Name' :
                        (name.includes('OutBrain') ? 'Marketer Name' :
                            'Advertiser Name'
                        )}
                    </TableCell>
                    <TableCell align="right">Spend</TableCell>
                    <TableCell align="right">Clicks</TableCell>
                    <TableCell align="right">Impressions</TableCell>
                    <TableCell align="right">CPC</TableCell>
                    <TableCell align="right">CTR</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {facebook_advertisers.map((advertiser) => (
                    <TableRow key={advertiser.advertiser_id}>
                      <TableCell component="th" scope="row">
                        {advertiser.advertiser_id}
                      </TableCell>
                      <TableCell>
                        {advertiser.advertiser_name}
                      </TableCell>
                      <TableCell align="right">
                        {advertiser.spend.toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        {advertiser.clicks}
                      </TableCell>
                      <TableCell align="right">
                        {advertiser.impressions}
                      </TableCell>
                      <TableCell align="right">
                        {(advertiser.spend / advertiser.clicks || 0).toFixed(3)}
                      </TableCell>
                      <TableCell align="right">
                        {(advertiser.clicks * 100 / advertiser.impressions || 0).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const RowTableRevenue = ({name, revenue, conversions, epc, campaigns}) => {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {name}
        </TableCell>
        <TableCell align="right">{revenue.toFixed(2)}</TableCell>
        <TableCell align="right">{conversions}</TableCell>
        <TableCell align="right">{epc.toFixed(3)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{margin: 1}}>
              <Typography variant="h6" gutterBottom component="div">
                Statistic
                by {name === 'YahooCodefuel' || name === 'YahooSearch' || name === 'RSOC' ? 'Channels' : 'Campaigns'}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>{name === 'YahooCodefuel' || name === 'YahooSearch' || name === 'RSOC' ? 'Channel ID' : 'Campaign ID'}</TableCell>
                    <TableCell>{name === 'YahooCodefuel' || name === 'YahooSearch' || name === 'RSOC' ? 'Channel Name' : 'Campaign Name'}</TableCell>
                    <TableCell align="right">Revenue</TableCell>
                    <TableCell align="right">Conversions</TableCell>
                    <TableCell align="right">EPC</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {campaigns.map((campaign) => (
                    <TableRow key={campaign.id_campaign || campaign.channel_id}>
                      <TableCell component="th" scope="row">
                        {campaign.id_campaign || campaign.channel_id}
                      </TableCell>
                      <TableCell>
                        {campaign.name_campaign || campaign.name_channel}
                      </TableCell>
                      <TableCell align="right">
                        {campaign.revenue.toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        {campaign.conversions}
                      </TableCell>
                      <TableCell align="right">
                        {(campaign.revenue / campaign.conversions || 0).toFixed(3)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const Dashboard = ({isAuthenticated}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const routeParams = useParams();
  const cid = routeParams.cid;

  const startStr = useSelector(state => state.date.startDateStr);
  const endStr = useSelector(state => state.date.endDateStr);

  const [apiData, setApiData] = useState({
    date: [],
    spend: [],
    impressions: [],
    clicks: [],
    revenue: [],
    conversions: [],
    net: [],
    cpa: [],
    epc: [],
    roi: [],
    user_name: '',
    user_id: 0,
    total_spend_facebook: 0,
    total_clicks_facebook: 0,
    total_impressions_facebook: 0,
    facebook_advertisers: [],
    total_spend_tiktok: 0,
    total_clicks_tiktok: 0,
    total_impressions_tiktok: 0,
    tiktok_advertisers: [],
    total_spend_taboola: 0,
    total_clicks_taboola: 0,
    total_impressions_taboola: 0,
    taboola_advertisers: [],
    total_spend_outbrain: 0,
    total_clicks_outbrain: 0,
    total_impressions_outbrain: 0,
    outbrain_advertisers: [],
    total_spend_zemanta: 0,
    total_clicks_zemanta: 0,
    total_impressions_zemanta: 0,
    zemanta_advertisers: [],
    total_ads_revenue: 0,
    total_ads_conversions: 0,
    total_ads_epc: 0,
    ads_campaigns: [],
    total_cross_revenue: 0,
    total_cross_conversions: 0,
    total_cross_epc: 0,
    cross_campaigns: [],
    total_tonic_revenue: 0,
    total_tonic_conversions: 0,
    total_tonic_epc: 0,
    tonic_campaigns: [],
    total_sedo_revenue: 0,
    total_sedo_conversions: 0,
    total_sedo_epc: 0,
    sedo_campaigns: [],
    total_ads2_revenue: 0,
    total_ads2_conversions: 0,
    total_ads2_epc: 0,
    ads2_campaigns: [],
    total_tonic2_revenue: 0,
    total_tonic2_conversions: 0,
    total_tonic2_epc: 0,
    tonic2_campaigns: [],
    total_system1_revenue: 0,
    total_system1_conversions: 0,
    total_system1_epc: 0,
    system1_campaigns: [],
    total_rsoccodefuel_revenue: 0,
    total_rsoccodefuel_conversions: 0,
    total_rsoccodefuel_epc: 0,
    rsoccodefuel_campaigns: [],
    total_yahoo_codefuel_revenue: 0,
    total_yahoo_codefuel_conversions: 0,
    total_yahoo_codefuel_epc: 0,
    yahoo_codefuel_campaigns: [],
    total_yahoo_search_revenue: 0,
    total_yahoo_search_conversions: 0,
    total_yahoo_search_epc: 0,
    yahoo_search_campaigns: [],
    total_spend: 0,
    total_clicks: 0,
    total_impressions: 0,
    total_revenue: 0,
    total_conversions: 0,
    total_roi: 0
  });

  const [searchParams, setSearchParams] = useSearchParams();

  // Facebook Connect
  const sendCode = () => {
    if (searchParams.get('code')) {
      axios.get(`${process.env.REACT_APP_API_URL}/api/facebook/connect/?code=${searchParams.get('code')}`, requestConfig)
        .then(function (response) {
          if (response.status === 200) {
            toast.success("Success connect");
          }
          if (response.status === 204) {
            toast.error(response.data ? response.data : 'Invalid verification code format');
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);


  useEffect(() => {
    sendCode();
  }, [searchParams]);

  useEffect(() => {
    get_data();
  }, [startStr, endStr]);


  const get_data = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/dashboard/total_data/?start_date=${startStr}&end_date=${endStr}&user=${cid}`, requestConfig)
      .then(function (response) {
        if (response.status === 200 && response.data.date) {
          let answer = response.data;
          let new_date = [];
          for (let el in answer.date) {
            new_date.push(new Date(answer.date[el]));
          }
          answer['date'] = new_date;
          setApiData(answer);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <Box>
      <Header title="Dashboard" subtitle={`user ID - ${apiData.user_id}, user name - ${apiData.user_name}`}/>
      <Box>
        {/* Grid and Charts */}

        <Box m="20px 10px 10px 10px" sx={{width: '100%'}}>
          <TableContainer>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell align="right">Spend</TableCell>
                  <TableCell align="right">Clicks</TableCell>
                  <TableCell align="right">Impressions</TableCell>
                  <TableCell align="right">Revenue</TableCell>
                  <TableCell align="right">NET</TableCell>
                  <TableCell align="right">Conversions</TableCell>
                  <TableCell align="right">CTR</TableCell>
                  <TableCell align="right">CPA</TableCell>
                  <TableCell align="right">EPC</TableCell>
                  <TableCell align="right">ROI</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="right">{apiData.total_spend.toFixed(2)}</TableCell>
                  <TableCell align="right">{apiData.total_clicks}</TableCell>
                  <TableCell align="right">{apiData.total_impressions}</TableCell>
                  <TableCell align="right">{apiData.total_revenue.toFixed(2)}</TableCell>
                  <TableCell align="right">{(apiData.total_revenue - apiData.total_spend).toFixed(2)}</TableCell>
                  <TableCell align="right">{apiData.total_conversions}</TableCell>
                  <TableCell
                    align="right">{(apiData.total_clicks * 100 / apiData.total_impressions || 0).toFixed(2)} %</TableCell>
                  <TableCell
                    align="right">{(apiData.total_spend / apiData.total_conversions || 0).toFixed(3)}</TableCell>
                  <TableCell
                    align="right">{(apiData.total_revenue / apiData.total_conversions || 0).toFixed(3)}</TableCell>
                  <TableCell align="right">{apiData.total_roi.toFixed(2)} %</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        {/* ROW 1 */}
        <Box
          m="40px 0 0 0"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          // gridAutoRows="240hv"
          gap="20px"
        >

          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title="Spend"
              icon={
                <EmailIcon
                  sx={{color: colors.greenAccent[600], fontSize: "26px"}}
                />
              }
              date={apiData.date}
              data={apiData.spend}
            />
          </Box>
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title="Revenue"
              icon={
                <EmailIcon
                  sx={{color: colors.greenAccent[600], fontSize: "26px"}}
                />
              }
              date={apiData.date}
              data={apiData.revenue}
            />
          </Box>
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title="NET"
              icon={
                <EmailIcon
                  sx={{color: colors.greenAccent[600], fontSize: "26px"}}
                />
              }
              date={apiData.date}
              data={apiData.net}
            />
          </Box>
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title="ROI"
              icon={
                <EmailIcon
                  sx={{color: colors.greenAccent[600], fontSize: "26px"}}
                />
              }
              date={apiData.date}
              data={apiData.roi}
            />
          </Box>

        </Box>

        {/* ROW 2 */}
        <Box
          m="40px 0 0 0"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="240hv"
          gap="20px"
        >

          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title="Clicks"
              icon={
                <EmailIcon
                  sx={{color: colors.greenAccent[600], fontSize: "26px"}}
                />
              }
              date={apiData.date}
              data={apiData.clicks}
            />
          </Box>
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title="Conversions"
              icon={
                <EmailIcon
                  sx={{color: colors.greenAccent[600], fontSize: "26px"}}
                />
              }
              date={apiData.date}
              data={apiData.conversions}
            />
          </Box>
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title="CPA"
              icon={
                <EmailIcon
                  sx={{color: colors.greenAccent[600], fontSize: "26px"}}
                />
              }
              date={apiData.date}
              data={apiData.cpa}
            />
          </Box>
          <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title="EPC"
              icon={
                <EmailIcon
                  sx={{color: colors.greenAccent[600], fontSize: "26px"}}
                />
              }
              date={apiData.date}
              data={apiData.epc}
            />
          </Box>

        </Box>

        <Box
          m="40px 0 0 0"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="240hv"
          gap="20px"
        >
          <MultiLineChart data={apiData} cid={cid}/>
        </Box>

      </Box>

      {/* Table ADS systems */}
      <Box m="20px 10px 10px 10px" sx={{width: '100%'}}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell/>
                <TableCell>Source</TableCell>
                <TableCell align="right">Spend</TableCell>
                <TableCell align="right">Clicks</TableCell>
                <TableCell align="right">Impressions</TableCell>
                <TableCell align="right">CPC</TableCell>
                <TableCell align="right">CTR</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

              {/* {rows.map((row) => (
                  <Row key={row.name} row={row} />
                ))} */}
              {RowTable('Facebook', apiData.total_spend_facebook, apiData.total_clicks_facebook, apiData.total_impressions_facebook,
                apiData.facebook_advertisers)}
              {RowTable('TikTok', apiData.total_spend_tiktok, apiData.total_clicks_tiktok, apiData.total_impressions_tiktok,
                apiData.tiktok_advertisers)}
              {RowTable('Taboola', apiData.total_spend_taboola, apiData.total_clicks_taboola, apiData.total_impressions_taboola,
                apiData.taboola_advertisers)}
              {RowTable('OutBrain', apiData.total_spend_outbrain, apiData.total_clicks_outbrain, apiData.total_impressions_outbrain,
                apiData.outbrain_advertisers)}
              {RowTable('Zemanta', apiData.total_spend_zemanta, apiData.total_clicks_zemanta, apiData.total_impressions_zemanta,
                apiData.zemanta_advertisers)}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Table Revenue */}
      <Box m="20px 10px 10px 10px" sx={{width: '100%'}}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell/>
                <TableCell>Source</TableCell>
                <TableCell align="right">Revenue</TableCell>
                <TableCell align="right">Conversions</TableCell>
                <TableCell align="right">EPC</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {RowTableRevenue({
                name: 'Ads',
                revenue: apiData.total_ads_revenue,
                conversions: apiData.total_ads_conversions,
                epc: apiData.total_ads_epc,
                campaigns: apiData.ads_campaigns
              })}
              {RowTableRevenue({
                name: 'Cross',
                revenue: apiData.total_cross_revenue,
                conversions: apiData.total_cross_conversions,
                epc: apiData.total_cross_epc,
                campaigns: apiData.cross_campaigns
              })}
              {RowTableRevenue({
                name: 'Tonic',
                revenue: apiData.total_tonic_revenue,
                conversions: apiData.total_tonic_conversions,
                epc: apiData.total_tonic_epc,
                campaigns: apiData.tonic_campaigns
              })}
              {RowTableRevenue({
                name: 'Sedo',
                revenue: apiData.total_sedo_revenue,
                conversions: apiData.total_sedo_conversions,
                epc: apiData.total_sedo_epc,
                campaigns: apiData.sedo_campaigns
              })}
              {RowTableRevenue({
                name: 'Ads 2',
                revenue: apiData.total_ads2_revenue,
                conversions: apiData.total_ads2_conversions,
                epc: apiData.total_ads2_epc,
                campaigns: apiData.ads2_campaigns
              })}
              {RowTableRevenue({
                name: 'Tonic 2',
                revenue: apiData.total_tonic2_revenue,
                conversions: apiData.total_tonic2_conversions,
                epc: apiData.total_tonic2_epc,
                campaigns: apiData.tonic2_campaigns
              })}
              {RowTableRevenue({
                name: 'System1',
                revenue: apiData.total_system1_revenue,
                conversions: apiData.total_system1_conversions,
                epc: apiData.total_system1_epc,
                campaigns: apiData.system1_campaigns
              })}
              {RowTableRevenue({
                name: 'YahooCodefuel',
                revenue: apiData.total_yahoo_codefuel_revenue,
                conversions: apiData.total_yahoo_codefuel_conversions,
                epc: apiData.total_yahoo_codefuel_epc,
                campaigns: apiData.yahoo_codefuel_campaigns
              })}
              {RowTableRevenue({
                name: 'YahooSearch',
                revenue: apiData.total_yahoo_search_revenue,
                conversions: apiData.total_yahoo_search_conversions,
                epc: apiData.total_yahoo_search_epc,
                campaigns: apiData.yahoo_search_campaigns
              })}
              {RowTableRevenue({
                name: 'RSOC',
                revenue: apiData.total_rsoccodefuel_revenue,
                conversions: apiData.total_rsoccodefuel_conversions,
                epc: apiData.total_rsoccodefuel_epc,
                campaigns: apiData.rsoccodefuel_campaigns
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

    </Box>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user
});

export default connect(mapStateToProps)(Dashboard);
